<template>
  <v-container fluid class="primary" style="overflow-y: scroll">
    <v-row style="" class="ml-6">
      <v-col class="ml-6 d-flex flex-column justify-start">
        <v-card class="mb-6">
          <v-btn
            @click="getData()"
            :disabled="refreshDisabled"
            absolute
            mr-0
            class=""
            outlined
            color="primary"
            style="position: absolute; top: 10px; right: 10px;"
            >Refresh Data</v-btn
          >
          <v-card-title> Welcome to Booking Admin V3! </v-card-title>
          <hr />

          <v-card-text>
            <p>
              This improved administration dashboard has been completely
              redesigned, with a focus on performance, ease of use, and
              accuracy.
            </p>

            <p>
              Questions? Please contact us at
              <a href="https://client.techspace.co.th" target="_blank"
                >client.techspace.co.th</a
              >
            </p>
            <p>
              <a
                href="https://reddguide.poem.global/customer-service/คำถามทพบบอย-faqs/booking-admin/issue-submission"
                target="_blank"
                >Click here</a
              >
              for guidelines on submitting tickets.
            </p>
            <v-row>
              <v-col class="xs12 lg6">
                <p>
                  <strong>Starting data sync at {{ startLoadData }}</strong>
                </p>
                <p></p>
                <table>
                  <tr>
                    <td>
                      <v-progress-circular
                        v-if="loadingUnits"
                        indeterminate
                        color="primary"
                      ></v-progress-circular>
                      <v-progress-circular
                        v-if="loadingUnits === false"
                        :value="100"
                        color="green"
                      ></v-progress-circular>
                    </td>
                    <td style="padding-left: 10px;">
                      {{ msgLoadingUnits }}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <v-progress-circular
                        v-if="loadingUsers"
                        indeterminate
                        color="primary"
                      ></v-progress-circular>
                      <v-progress-circular
                        v-if="loadingUsers === false"
                        :value="100"
                        color="green"
                      ></v-progress-circular>
                    </td>
                    <td style="padding-left: 10px;">
                      {{ msgLoadingUsers }}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <v-progress-circular
                        v-if="loadingBookings"
                        indeterminate
                        color="primary"
                      ></v-progress-circular>
                      <v-progress-circular
                        v-if="loadingBookings === false"
                        :value="100"
                        color="green"
                      ></v-progress-circular>
                    </td>
                    <td style="padding-left: 10px;">
                      {{ msgLoadingBookings }}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <v-progress-circular
                        v-if="loadingPricing"
                        indeterminate
                        color="primary"
                      ></v-progress-circular>
                      <v-progress-circular
                        v-if="loadingPricing === false"
                        :value="100"
                        color="green"
                      ></v-progress-circular>
                    </td>
                    <td style="padding-left: 10px;">
                      {{ msgLoadingPricing }}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <v-progress-circular
                        v-if="loadingPaymentLinks"
                        indeterminate
                        color="primary"
                      ></v-progress-circular>
                      <v-progress-circular
                        v-if="loadingPaymentLinks === false"
                        :value="100"
                        color="green"
                      ></v-progress-circular>
                    </td>
                    <td style="padding-left: 10px;">
                      {{ msgLoadingPaymentLinks }}
                    </td>
                  </tr>
                </table>

                <p class="my-3">
                  <strong>{{ msgAllDataLoaded }}</strong>
                </p>
              </v-col>
              <v-col class="xs12 lg6">
                <p><strong>Scheduled Functions Status:</strong></p>
                <div
                  v-for="activity in activities"
                  style="margin-bottom: 15px;"
                >
                  <strong>{{ activity.activity }}: </strong> <br />{{
                    formatLocalTime(activity.createdOn)
                  }}
                </div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import moment from "moment";
import helpers from "@/plugins/helpers";
// import BulletinModal from "@/components/BulletinModal";
const axios = require("axios");
axios.defaults.withCredentials = true;
export default {
  name: "Dashboard",
  data: () => ({
    startLoadData: null,
    // endLoadData: null,
    loadingUnits: true,
    msgLoadingUnits: "Loading Units",
    loadingUsers: true,
    msgLoadingUsers: "Loading Users",
    loadingBookings: true,
    msgLoadingBookings: "Loading Bookings",
    loadingPricing: true,
    msgLoadingPricing: "Loading Pricing",
    loadingPaymentLinks: true,
    msgLoadingPaymentLinks: "Loading Payment Links",
    msgAllDataLoaded: "",
    refreshDisabled: false,
    activities: []
  }),
  components: {
    // BulletinModal
  },
  computed: {
    token() {
      return this.$store.state.user.token;
    },
    role() {
      return this.$store.state.user.role;
    }
  },
  methods: {
    friendlyDate(d) {
      return helpers.formatFriendlyDate(d);
    },
    formatLocalTime(d) {
      return helpers.formatLocalDateTime(d);
    },
    async getData() {
      let vm = this;
      this.refreshDisabled = true;
      this.msgAllDataLoaded = "";
      this.msgLoadingUnits = "Loading Units";
      this.msgLoadingUsers = "Loading Users";
      this.msgLoadingBookings = "Loading Bookings";
      this.msgLoadingPricing = "Loading Pricing";
      this.msgLoadingPaymentLinks = "Loading Payment Links";
      this.startLoadData = moment().format("h:mm:ss a");

      await axios
        .all([
          this.getUnits(),
          this.getUsers(),
          this.getBookings(),
          this.getPricing(),
          this.getPaymentLinks()
        ])
        .then(
          axios.spread((...res) => {
            // console.log('all calls done?')
            // console.log(res);
            // console.log(res[0]);
            // console.log(res[1]);
            // console.log(res[2]);

            vm.loadingBookings = false;
            vm.loadingUnits = false;
            vm.loadingUsers = false;
            vm.loadingPricing = false;
            vm.loadingPaymentLinks = false;
            vm.msgLoadingBookings = `Loading Bookings finished`;
            vm.msgLoadingUnits = `Loading Units finished`;
            vm.msgLoadingUsers = `Loading Users finished`;
            vm.msgLoadingPricing = `Loading Pricing finished`;
            vm.msgLoadingPaymentLinks = `Loading Payment Links finished`;

            // STORE UNIT DATA IN STATE
            var units = res[0].data;
            vm.$store.commit("setUnits", {
              units
            });

            // FILTER USER DATA BASED ON LOGGED IN USERS ROLE
            let users = res[1].data;
            if (vm.role === "Chief" || vm.role === "Chief In Training") {
              users = users.filter(u => u.role === "customer");
            }
            if (vm.role === "Head") {
              users = users.filter(u =>
                ["Chief", "Chief In training", "customer"].includes(u.role)
              );
            }

            // STORE USER DATA IN STATE
            vm.$store.commit("setUsers", {
              users
            });
            // LOOP BOOKINGS AND INSERT UNIT DETAILS
            var bookings = res[2].data;
            for (var i = bookings.length - 1; i >= 0; i--) {
              bookings[i].unitDetails = units.find(
                u => u._id === bookings[i].unitID
              );
            }
            // STORE BOOKING DATA IN STATE
            vm.$store.commit("setBookings", {
              bookings
            });

            // STORE PRICING DATA IN STATE
            var pricing = res[3].data;
            vm.$store.commit("setPricing", {
              pricing
            });

            // STORE PAYMENT LINKS IN STATE
          let paymentLinks = res[4].data;
          vm.$store.commit("setPaymentLinks", {
           paymentLinks
          });

            // SHOW DATA LOAD HAS ENDED WITH TIME
            let start = moment(vm.startLoadData, "h:mm:ss a");
            vm.msgAllDataLoaded = `Data sync completed in ${moment().diff(
              start,
              "seconds"
            )} seconds`;

            // ENABLE REFRESH BUTTON
            vm.refreshDisabled = false;
          })
        );
    },
    getBookings() {
      //v3 get booking data
      let vm = this;
      vm.loadingBookings = true;
      axios.defaults.headers.common = {
        Authorization: `Bearer ${vm.token}`
      };
      return axios.get(vm.$root.urls.api + "/v3/bookings", {
        withCredentials: true
      });
    },
    getUnits() {
      //v3 get unit data
      let vm = this;
      vm.loadingUnits = true;
      axios.defaults.headers.common = {
        Authorization: `Bearer ${vm.token}`
      };
      return axios.get(vm.$root.urls.api + "/v3/units", {
        withCredentials: true
      });
    },
    getUsers() {
      //v3 get user data
      let vm = this;
      vm.loadingUsers = true;
      axios.defaults.headers.common = {
        Authorization: `Bearer ${vm.token}`
      };
      return axios.get(vm.$root.urls.api + "/v3/users", {
        withCredentials: true
      });
    },
    getPricing() {
      //v3 get unit pricing data
      let vm = this;
      vm.loadingPricing = true;
      axios.defaults.headers.common = {
        Authorization: `Bearer ${vm.token}`
      };
      return axios.get(vm.$root.urls.api + "/v3/prices", {});
    },
    getActivities() {
      let vm = this;
      // query api for users
      axios.defaults.headers.common = { Authorization: `Bearer ${vm.token}` };
      axios
        .get(vm.$root.urls.api + "/activities/500", {})
        .then(function(results) {
          let data = results.data;
          data = data.filter(d => d.role === "SYSTEM");
          const dataRecent = data.slice(0, 5);

          vm.activities = dataRecent;
        });
    },
    getPaymentLinks() {
      let vm = this;
      axios.defaults.headers.common = {
        Authorization: `Bearer ${vm.token}`
      };
      return axios
        .get(vm.$root.urls.api + "/v3/paymentLink/paid", {})
    }
    //     .then(function(results) {
    //       // console.log(results.data);
    //       vm.paymentLinks = results.data;
    //     })
    //     .catch(function(error) {
    //       console.log(error);
    //     });
    // }
  },
  mounted: function() {
    this.getData();
    this.getActivities();
    /* let vm = this;
    window.setInterval(() => {
      vm.getData(); // call any function or end point
    }, 300000); // interval set to one sec. */
  }
};
</script>
